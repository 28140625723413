import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  faTriangleExclamation,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useServiceAuth from 'auth/useServiceAuth';
import { submitKuali } from 'apis/adminAPIs';
// import StaticData from './StaticData';
import { Button } from '@asu/components-core';
import { classNames } from 'utils';

const KualiProgramRow = ({
  program,
  checksheetList,
  filters,
  index,
  refetchChecksheets, // Function to refresh data
  refetchKualiProgress,
  isLoading,
  user,
}) => {
  const { getAccessToken } = useServiceAuth();
  const [loading, setLoading] = useState(false); // Submission loading state
  const [resubmitting, setResubmitting] = useState(false); // Resubmission loading state
  const [checksheetFound, setChecksheetFound] = useState(null);
  const [kualiStatus, setKualiStatus] = useState(null);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [submitted, setSubmitted] = useState(false); // Track if submission has occurred

  const degree =
    program.acadPlanType === 'CER'
      ? ', Certificate'
      : program.acadPlanType === 'MIN'
        ? ', Minor'
        : program.acadPlanType && program.degreeDescriptionShort
          ? `, ${program.degreeDescriptionShort}`
          : null;

  const handleSubmitById = async (checksheetId, isResubmitting = false) => {
    try {
      console.log(
        isResubmitting ? 'Resubmitting...' : 'Submitting...',
        checksheetId
      );
      if (isResubmitting) {
        setResubmitting(true); // Set resubmitting state
      } else {
        setLoading(true); // Set loading state
        setSubmitted(true); // Hide submit button
      }

      const token = await getAccessToken();
      const jsonData = { id: checksheetId };
      await submitKuali({ jsonData, token });

      // Simulate a delay for refreshing data after 2 seconds
      setTimeout(() => {
        refetchChecksheets(); // Re-fetch data after submission
        refetchKualiProgress(); // Re-fetch data after submission
        setLoading(false);
        setResubmitting(false);
      }, 5000);
    } catch (error) {
      console.error('Submission failed:', error);
      setLoading(false);
      setResubmitting(false);
    }
  };

  useEffect(() => {
    const found = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    setChecksheetFound(found);
    setKualiStatus(found?.kualiStatus);

    const isDisabled =
      !!filters.length &&
      ((!!found?.status && !filters.includes(found.status)) ||
        (!found && !filters.includes('NONE')));

    setIsFilterDisabled(isDisabled);
  }, [checksheetList, filters, program, degree]);

  if (!program) return <div>Loading...</div>;

  return (
    <>
      {!!checksheetFound && user.role.includes('PROVOST') && (
        <div>
          <div
            key={program.acadPlanCode}
            className={classNames(
              isFilterDisabled && 'bg-gray-1 text-gray-6 pe-none',
              !!checksheetFound &&
                user.role.includes('PROVOST') &&
                'program-row',
              'row mx-0'
            )}
          >
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto ps-0 fw-bold py-2'
              )}
              style={{ width: '30%' }}
            >
              {!!checksheetFound && (
                <Link
                  className="underline-hover text-gray-7"
                  to={`/checksheet?id=${checksheetFound.id}`}
                >
                  {program.programDescr}
                  {degree}
                </Link>
              )}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '12%' }}
            >
              {program.acadPlanCode}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '12%' }}
            >
              {program.subplan?.acadSubPlanCode}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '9%' }}
            >
              {program.planExtensionType}
            </div>
            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto py-2'
              )}
              style={{ width: '9%' }}
            >
              {program.planExtensionDefaultFlag}
            </div>

            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto gap-3 pe-1'
              )}
              style={{
                height: 'fit-content',
                width: '18%',
                padding: '12px 20px 8px',
              }}
            >
              {kualiStatus === '' ||
              kualiStatus === null ||
              kualiStatus === undefined ? (
                <div></div>
              ) : kualiStatus === 'SUCCESS' ? (
                <div className="text-success-custom fw-bold">
                  <FontAwesomeIcon icon={faCheckCircle} /> Success
                </div>
              ) : (
                <>
                  <div className="text-danger-custom fw-bold">
                    <FontAwesomeIcon icon={faTriangleExclamation} /> Failed to
                    submit
                  </div>
                  <div style={{ fontSize: '12px' }}>{kualiStatus}</div>
                </>
              )}
            </div>

            <div
              className={classNames(
                !!index && 'border-top border-gray-3',
                'col-auto d-flex gap-3 pe-1'
              )}
              style={{
                height: 'fit-content',
                width: '10%',
                padding: '12px 20px 8px',
              }}
            >
              {loading ? (
                <div className="text-danger-custom fw-bold">Submitting...</div>
              ) : kualiStatus === '' ||
                kualiStatus === null ||
                kualiStatus === undefined ? (
                !submitted && (
                  <Button
                    label="Submit"
                    color="maroon"
                    size="small"
                    onClick={() => handleSubmitById(checksheetFound.id)}
                  />
                )
              ) : kualiStatus === 'SUCCESS' ? (
                <div>
                  <Button
                    label="Submit"
                    color="maroon"
                    size="small"
                    onClick={() => handleSubmitById(checksheetFound.id)}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    {resubmitting ? (
                      <div className="text-danger-custom fw-bold">
                        Resubmitting...
                      </div>
                    ) : (
                      <Button
                        label="Resubmit"
                        color="maroon"
                        size="small"
                        onClick={() =>
                          handleSubmitById(checksheetFound.id, true)
                        }
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default KualiProgramRow;
