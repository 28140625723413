// React
import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRolloverStatus,
  setPolling,
  setIsInitialRun as setGlobalInitial,
  setError,
} from 'state/slices/rolloverSlice';
import { ToastContainer } from 'react-bootstrap';

// pages
import Login from 'pages/Login';
import PATHome from 'pages/PATHome';
import Component from 'pages/Component';
import Components from 'pages/Components';
import Template from 'pages/Template';
import Templates from 'pages/Templates';
import Programs from 'pages/Programs';
import PlanExtension from 'pages/PlanExtension';
import Kuali from 'pages/Kuali';
import Users from 'pages/Users';
import ChecksheetCatalogChanges from 'pages/ChecksheetCatalogChanges';
import Checksheet from 'pages/Checksheet';
import CourseList from 'pages/CourseList';
import Settings from 'pages/Settings';
import RoutesWrapper from 'components/RoutesWrapper';
// import NavigationSidebar from 'components/NavigationSidebar';
import TemplateSidebar from 'components/TemplateSidebar';
// import ChecksheetValidation from 'components/ChecksheetValidation';
import UserRoute from 'components/UserRoute';
import SubplanActivation from 'pages/SubplanActivation';
// import PATHeader from 'components/PATHeader';
import ScrollToTop from 'components/ScrollToTop';
import Header from 'components/Header';
// import Footer from "components/Footer";
import RolloverToast from 'components/RolloverToast';

// authorization
import ServiceAuthRoute from 'auth/ServiceAuthRoute';
import ServiceAuthProvider from 'auth/ServiceAuthProvider';
import useServiceAuth from 'auth/useServiceAuth';

// style sheets
import './App.css';
import Rollover from 'pages/Rollover';
import RolloverSuccess from 'pages/RolloverSuccess';
import { checksheetCopyAllProgress } from 'apis/checksheetAPIs';
import RecallRequests from 'pages/RecallRequest';
import { fetchDplColleges } from 'apis/dplAPIs';
import { setCollegeList, setDepartmentList } from 'state/slices/dataListsSlice';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const DataFetchWrapper = ({ children }) => {
  const dispatch = useDispatch();

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
    // isPending: fetchCollegesIsPending,
    // fetchStatus: fetchCollegesFetchStatus,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const findChildAcadOrgs = useCallback((parent) => {
    if (!!parent?.childAcadOrgs?.length) {
      const childDepartmentList = [];

      parent.childAcadOrgs.forEach((child) => {
        childDepartmentList.push(child);
        const children = findChildAcadOrgs(child);

        if (children) childDepartmentList.push(...children);
      });

      return childDepartmentList;
    } else return;
  }, []);

  useEffect(() => {
    if (fetchCollegesIsError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const collegesObject = fetchCollegesData.reduce((acc, college) => {
        const { acadOrgCode, ...rest } = college;
        acc[acadOrgCode] = rest;
        return acc;
      }, {});

      const departmentsArray = [];

      fetchCollegesData.forEach((coll) => {
        const childAcadOrgs = findChildAcadOrgs(coll);
        if (childAcadOrgs) {
          departmentsArray.push(...childAcadOrgs);
          collegesObject[coll.acadOrgCode].departments = childAcadOrgs.reduce(
            (acc, dept) => {
              const { acadOrgCode, ...rest } = dept;
              acc[acadOrgCode] = rest;
              return acc;
            },
            {}
          );
        }
      });

      const departmentsObject = departmentsArray.reduce((acc, dept) => {
        const { acadOrgCode, ...rest } = dept;
        acc[acadOrgCode] = rest;
        return acc;
      }, {});

      dispatch(setCollegeList(collegesObject));
      dispatch(setDepartmentList(departmentsObject));
    }
  }, [
    dispatch,
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsError,
    fetchCollegesIsSuccess,
    findChildAcadOrgs,
  ]);

  return <>{children}</>;
};

const RolloverProgressWrapper = ({ children }) => {
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const [rolloverFinished, setRolloverFinished] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isInitialRun, setIsInitialRun] = useState(true);

  const isPolling = useSelector((state) => state.rollover.isPolling);
  const initiator = useSelector((state) => state.rollover.initiator);
  const asurite = useSelector((state) => state.user.asurite);
  const role = useSelector((state) => state.user.role);

  const userIsProvost = role?.includes('PROVOST');

  const { data, isError, refetch } = useQuery({
    enabled: isAuthenticated && isPolling && userIsProvost,
    queryKey: ['checksheetCopyAllProgress'],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetCopyAllProgress({ token });
    },
    refetchInterval: ({ state }) => {
      // console.log('Polling: ', isPolling);
      // console.log('Current status: ', state.data);
      if ((!isPolling && state.data?.currentStatus === 'DONE') || !state.data) {
        // console.log('Ending fetch');
        return false;
      }

      if (state.data?.currentStatus === 'IN PROGRESS') {
        // console.log(state.data);
        setIsInitialRun(false);
      }

      // console.log('Continuing fetch');
      return 10000;
    },
  });

  useEffect(() => {
    if (isAuthenticated && isPolling && userIsProvost) {
      // console.log('Refetching');
      setRolloverFinished(false);
      setShowAlert(true);
      refetch();
    }
  }, [isAuthenticated, isPolling, refetch, userIsProvost]);

  useEffect(() => {
    if (data) {
      dispatch(setRolloverStatus(data.currentStatus));

      if (data.currentStatus === 'DONE') {
        // console.log('Setting polling to false');
        setRolloverFinished(true);
        dispatch(setPolling(false));
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setGlobalInitial(isInitialRun));
  }, [dispatch, isInitialRun]);

  useEffect(() => {
    if (isError) dispatch(setError(true));
  }, [dispatch, isError]);

  return (
    <>
      {children}
      {!isInitialRun &&
        showAlert &&
        asurite === initiator &&
        location.pathname !== '/rollover' &&
        location.pathname !== '/rollover-success' &&
        (rolloverFinished || data?.currentStatus === 'IN PROGRESS') && (
          <ToastContainer
            className="p-3"
            containerPosition="fixed"
            position={'bottom-end'}
            style={{ zIndex: 1 }}
          >
            <RolloverToast
              finished={rolloverFinished}
              handleClose={() => setShowAlert(false)}
            />
          </ToastContainer>
        )}
    </>
  );
};

const NavigationSidebarLayout = () => {
  return (
    <>
      {/* <NavigationSidebar /> */}
      <div
        className="container-xl"
        // style={{ maxWidth: '1500px' }}
      >
        <Outlet />
      </div>
    </>
  );
};

const ComponentLibraryLayout = () => {
  return (
    <>
      <TemplateSidebar />
      <div className="d-flex justify-content-end">
        <div className="bg-white" style={{ width: '80%' }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

// const ChecksheetValidationLayout = () => {
//   return (
//     <>
//       <ChecksheetValidation />
//       <div className="d-flex justify-content-end">
//         <div className="bg-white" style={{ width: '80%' }}>
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// };

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          {/* Not sure this is the best way for scrolling to top but it works for now*/}
          <ScrollToTop />
          <ServiceAuthProvider>
            <DataFetchWrapper>
              <RolloverProgressWrapper>
                <Header />
                <RoutesWrapper>
                  {/* <PATHeader /> */}
                  <Routes>
                    <Route
                      element={
                        <ServiceAuthRoute>
                          <NavigationSidebarLayout />
                        </ServiceAuthRoute>
                      }
                    >
                      <Route path="/" element={<PATHome />} />
                      <Route path="/programs" element={<Programs />} />
                      <Route
                        path="/templates"
                        element={
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Templates />
                          </UserRoute>
                        }
                      />
                    </Route>
                    <Route element={<ComponentLibraryLayout />}>
                      <Route
                        path="/template"
                        element={
                          <ServiceAuthRoute>
                            <UserRoute
                              allowProvost={true}
                              allowCollege={false}
                              allowDepartment={false}
                              allowDars={false}
                            >
                              <Template />
                            </UserRoute>
                          </ServiceAuthRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/courselist"
                      element={
                        <ServiceAuthRoute>
                          <CourseList />
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/component"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Component />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/components"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Components />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Users />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/subplanactivation"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <SubplanActivation />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/settings"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Settings />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/rollover"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Rollover />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/rollover-success"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <RolloverSuccess />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/checksheet-catalog-changes"
                      element={
                        <ServiceAuthRoute>
                          <ChecksheetCatalogChanges />
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/plan-extension"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <PlanExtension />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/kuali"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Kuali />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    <Route
                      path="/recall-requests"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={true}
                            allowDepartment={false}
                            allowDars={false}
                            checkCollege={true}
                          >
                            <RecallRequests />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                    {/* <Route element={<ChecksheetValidationLayout />}> */}
                    <Route
                      path="/checksheet"
                      element={
                        <ServiceAuthRoute>
                          <Checksheet />
                        </ServiceAuthRoute>
                      }
                    />
                    {/* </Route> */}
                    <Route path="/login" element={<Login />} />
                  </Routes>
                </RoutesWrapper>
                {/* <Footer /> */}
              </RolloverProgressWrapper>
            </DataFetchWrapper>
          </ServiceAuthProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
