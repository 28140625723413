import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setAllProgramsList } from 'state/slices/dataListsSlice';
import {
  faCalendar,
  faCircle,
  faCircleInfo,
  faFilter,
  faSearch,
  faSort,
  // faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProposedRow from './ProposedRow';
import { setYear } from 'state/slices/settingsSlice';
import {
  // checksheetDelete,
  checksheetUpdateProgress,
} from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import StaticData from './StaticData';
import { Button } from '@asu/components-core';
import { Dropdown, Modal } from 'react-bootstrap';
import DropdownCustomToggle from './DropdownCustomToggle';
import LoadingSkeleton from './LoadingSkeleton';
import { subplanActivationGetAll } from 'apis/adminAPIs';
import { Link } from 'react-router-dom';
import { classNames } from 'utils';
import NewProposedProgramForm from './NewProposedProgramForm';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ProposedRows = ({
  degreeType,
  collegeCode = null,
  collegeName = null,
  fetchPrograms,
  programList,
  checksheetList,
  refetchChecksheets,
  getChecksheetIsLoading,
  setError,
  handleOpenDefinitions,
  user,
  year,
  showModal,
  setShowModal,
  handleCloseModal,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessToken } = useServiceAuth();

  const userIsProvost = user?.role?.includes('PROVOST');

  const [searchString, setSearchString] = useState('');
  const [departments, setDepartments] = useState([]);
  const [departmentLabel, setDepartmentLabel] = useState('Department');
  const [statusLabel, setStatusLabel] = useState('Status');
  const [totalProgramsShown, setTotalProgramsShown] = useState(0);
  const [sortBy, setSortBy] = useState(() => sortByNameAToZ);
  const [selectedChecksheets, setSelectedChecksheets] = useState([]);
  const [allChecksheetsSelected, setAllChecksheetsSelected] = useState(false);
  const [showDiv, setShowDiv] = useState(false);
  // const [deleteProgramsModal, setDeleteProgramsModal] = useState(false);
  const [departmentFilters, setDepartmentFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);

  const filterByCollege = useCallback(
    (checksheet) => {
      const splitId = checksheet.id.split('*');

      if (!collegeCode || collegeCode === splitId[1]) return true;

      return false;
    },
    [collegeCode]
  );

  const filterByDepartment = useCallback(
    (checksheet) => {
      if (departmentFilters.length === 0) return true;

      if (departmentFilters.includes(checksheet.checksheetObj.department))
        return true;

      return false;
    },
    [departmentFilters]
  );

  const filterByStatus = useCallback(
    (checksheet) => {
      if (statusFilters.length === 0) return true;

      if (statusFilters.includes(checksheet.status)) return true;

      return false;
    },
    [statusFilters]
  );

  const filteredChecksheetList = checksheetList
    .filter(filterByCollege)
    .filter(filterByDepartment)
    .filter(filterByStatus)
    .filter(
      (checksheet) =>
        checksheet.checksheetObj.checksheetName
          .toLowerCase()
          .includes(searchString.toLowerCase()) ||
        checksheet.checksheetObj.acadplan
          .toLowerCase()
          .includes(searchString.toLowerCase())
    );

  const collegeList = useSelector((state) => state.dataLists.collegeList);
  const departmentList = useSelector((state) => state.dataLists.departmentList);

  const {
    data: fetchSubplanActivationListData,
    error: fetchSubplanActivationListError,
    isError: fetchSubplanActivationListIsError,
    isSuccess: fetchSubplanActivationListIsSuccess,
    // isPending: fetchSubplanActivationListIsPending,
    // fetchStatus: fetchSubplanActivationListFetchStatus,
  } = useQuery({
    queryKey: ['subplanActivationList', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return subplanActivationGetAll({ year: year, token });
    },
    enabled: isAuthenticated,
  });

  const {
    mutate: updateChecksheetProgress,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetUpdateProgress,
    onSuccess: async (data, { token }) => {
      refetchChecksheets({ year, token });
      console.log('updateChecksheetProgress success');
    },
  });

  // const { mutate: deleteChecksheets } = useMutation({
  //   mutationFn: async ({ token }) => {
  //     const total = selectedChecksheets.length;
  //     const results = [];

  //     for (let i = 0; i < total; i++) {
  //       const result = await checksheetDelete({
  //         id: selectedChecksheets[i],
  //         token,
  //       });
  //       results.push(result);
  //       // You can even report progress if needed
  //       // setProgress((i + 1) / total * 100);
  //     }
  //     return results;
  //   },
  //   onSuccess: () => {
  //     setSelectedChecksheets([]);
  //     refetchChecksheets();
  //     setShowModal(() => false);
  //     setDeleteProgramsModal(() => false);
  //   },
  //   onError: () => {
  //     refetchChecksheets();
  //     setShowModal(() => false);
  //     setDeleteProgramsModal(() => false);
  //   },
  // });

  // const handleClearChecksheets = async () => {
  //   setDeleteProgramsModal(() => true);

  //   const confirmDelete = window.confirm(
  //     `Are you sure you want to clear the checksheet for ${selectedChecksheets.length} programs?`
  //   );

  //   if (confirmDelete) {
  //     const token = await getAccessToken();

  //     setShowModal(() => true);
  //     await deleteChecksheets({ token });
  //   }
  // };

  const handleSelectChecksheet = (checksheetId) => {
    if (!selectedChecksheets.includes(checksheetId))
      setSelectedChecksheets((prev) => [...prev, checksheetId]);
    else
      setSelectedChecksheets((prev) => prev.filter((p) => p !== checksheetId));
  };

  const handleSelectAllChecksheets = () => {
    if (selectedChecksheets.length === filteredChecksheetList.length) {
      setSelectedChecksheets([]);
      setAllChecksheetsSelected(false);
    } else {
      setSelectedChecksheets((clist) =>
        filteredChecksheetList.map((checksheet) => checksheet.id)
      );
      setAllChecksheetsSelected(true);
    }
  };

  const handleDeselectAllChecksheets = () => {
    setSelectedChecksheets([]);
    setAllChecksheetsSelected(false);
  };

  const handleSetProgress = async (progress) => {
    const jsonData = {
      ids: [...selectedChecksheets],
      progress,
      action: 'changed',
    };
    const token = await getAccessToken();

    await updateChecksheetProgress({
      jsonData: jsonData,
      token: token,
    });
  };

  function sortByNameAToZ(a, b) {
    const nameA = a.checksheetName;
    const nameB = b.checksheetName;

    const lowerA = nameA.toLowerCase();
    const lowerB = nameB.toLowerCase();

    if (lowerA === lowerB) return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;

    return lowerA < lowerB ? -1 : lowerA > lowerB ? 1 : 0;
  }

  function sortByNameZToA(a, b) {
    const nameA = a.checksheetName;
    const nameB = b.checksheetName;
    const lowerA = nameA.toLowerCase();
    const lowerB = nameB.toLowerCase();

    if (lowerA === lowerB) return nameA > nameB ? -1 : nameA < nameB ? 1 : 0;

    return lowerA > lowerB ? -1 : lowerA < lowerB ? 1 : 0;
  }

  const handleSortBy = (e) => {
    const value = e.target.value;
    setSortBy(
      value === 'nameZ-A' ? () => sortByNameZToA : () => sortByNameAToZ
    ); // Currently only sorts by name, need to know other options
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  // const handleFilterCollege = (e) => {
  //   const value = e.target.value;

  //   const currentColleges = [...collegeFilters];

  //   if (collegeFilters.includes(value)) {
  //     currentColleges.splice(collegeFilters.indexOf(value), 1);
  //   } else {
  //     currentColleges.push(value);
  //   }

  //   if (currentColleges.length === 0) setCollegeLabel('College/school');
  //   else if (currentColleges.length === 1) {
  //     const label = colleges.find(
  //       (college) => college[0] === currentColleges[0]
  //     )[1];
  //     setCollegeLabel(label);
  //   } else if (currentColleges.length > 1)
  //     setCollegeLabel(`College/school (${currentColleges.length})`);

  //   setCollegeFilters(currentColleges);
  // };

  // const handleUncheckAllColleges = () => {
  //   setCollegeLabel('College/school');
  //   setCollegeFilters([]);
  // };

  // const sortByCollege = (a, b) => {
  //   if (a[1] > b[1]) return 1;
  //   if (a[1] < b[1]) return -1;
  //   return 0;
  // };

  const handleFilterDepartment = (e) => {
    const value = e.target.value;

    const currentDepartments = [...departmentFilters];

    if (departmentFilters.includes(value)) {
      currentDepartments.splice(departmentFilters.indexOf(value), 1);
    } else {
      currentDepartments.push(value);
    }

    if (currentDepartments.length === 0) setDepartmentLabel('Department');
    else if (currentDepartments.length === 1) {
      const label = departments.find(
        (department) => department[0] === currentDepartments[0]
      )[1];
      setDepartmentLabel(label);
    } else if (currentDepartments.length > 1)
      setDepartmentLabel(`Department (${currentDepartments.length})`);

    setDepartmentFilters(currentDepartments);
  };

  const handleUncheckAllDepartments = () => {
    setDepartmentLabel('Department');
    setDepartmentFilters([]);
  };

  const sortByDepartment = (a, b) => {
    if (a[1] > b[1]) return 1;
    if (a[1] < b[1]) return -1;
    return 0;
  };

  // const filterByStatusForTotal = (program) => {
  //   if (statusFilters.length === 0) return true;

  //   const checksheetFound = checksheetList.find(
  //     (checksheet) =>
  //       checksheet.id.split('*')[2] === program.acadPlanCode &&
  //       (!!program.subplan
  //         ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
  //         : checksheet.id.split('*')[3] === 'null')
  //   );

  //   let included = false;

  //   if (
  //     (checksheetFound?.status &&
  //       statusFilters.includes(checksheetFound.status)) ||
  //     (!checksheetFound && statusFilters.includes('NONE'))
  //   )
  //     included = true;

  //   return included;
  // };

  const handleFilterStatus = (e) => {
    const value = e.target.value;

    const currentStatuses = [...statusFilters];

    if (statusFilters.includes(value)) {
      currentStatuses.splice(statusFilters.indexOf(value), 1);
    } else {
      currentStatuses.push(value);
    }

    if (currentStatuses.length === 0) setStatusLabel('Status');
    else if (currentStatuses.length === 1) {
      let label = null;
      if (currentStatuses[0] !== 'NONE') {
        label = StaticData.statusList.find(
          (status) => status.value === currentStatuses[0]
        ).label;
      } else label = 'No checksheet exists';
      setStatusLabel(label);
    } else if (currentStatuses.length > 1)
      setStatusLabel(`Status (${currentStatuses.length})`);

    setStatusFilters(currentStatuses);
  };

  const handleUncheckAllStatuses = () => {
    setStatusLabel('Status');
    setStatusFilters([]);
  };

  const handleClearAllFilters = () => {
    // setCollegeLabel('College/school');
    // setCollegeFilters([]);
    setDepartmentLabel('Department');
    setDepartmentFilters([]);
    setStatusLabel('Status');
    setStatusFilters([]);
  };

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };
  let frameId = useRef(null);

  const handleScroll = useCallback(() => {
    frameId.current = requestAnimationFrame(() => {
      const scrollPosition = window.scrollY;
      setShowDiv(scrollPosition > 285);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (frameId.current) cancelAnimationFrame(frameId.current);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (fetchPrograms.error) setError(fetchPrograms.error.message);
    if (fetchPrograms.isSuccess && fetchPrograms.fetchStatus === 'idle') {
      const next = [];

      let programsData = fetchPrograms.data;

      programsData.forEach((program) => {
        const obj = {
          acadPlanCode: program.acadPlanCode,
          acadPlanDescription: program.acadPlanMarketingDescription,
          degreeDescriptionShort: program.degreeDescriptionShort,
          campusesOffered: program.campusesOffered,
          acadPlanType: program.acadPlanType,
          owners: program.owners,
          subplan: null,
        };
        next.push(obj);

        if (fetchSubplanActivationListIsError)
          setError(fetchSubplanActivationListError.message);

        if (program.subplans && fetchSubplanActivationListIsSuccess)
          program.subplans.forEach((subplan) => {
            if (
              fetchSubplanActivationListData?.[program.acadPlanCode]?.[
                subplan.acadSubPlanCode
              ]
            ) {
              const subplanObj = {
                acadPlanCode: program.acadPlanCode,
                acadPlanDescription: program.acadPlanDescription,
                degreeDescriptionShort: program.degreeDescriptionShort,
                acadPlanType: program.acadPlanType,
                campusesOffered: program.campusesOffered,
                owners: program.owners,
                subplan: subplan,
              };

              next.push(subplanObj);
            }
          });
      });

      dispatch(setAllProgramsList([...next]));
    }
  }, [
    degreeType,
    dispatch,
    fetchPrograms.data,
    fetchPrograms.error,
    fetchPrograms.fetchStatus,
    fetchPrograms.isSuccess,
    fetchSubplanActivationListData,
    fetchSubplanActivationListError,
    fetchSubplanActivationListIsError,
    fetchSubplanActivationListIsSuccess,
    setError,
  ]);

  // useEffect(() => {
  //   const currentColleges = [...colleges];
  //   programList.forEach((program) => {
  //     program.owners.forEach((owner) => {
  //       if (
  //         !currentColleges.some(([key, value]) => key === owner.collegeAcadOrg)
  //       ) {
  //         currentColleges.push([
  //           owner.collegeAcadOrg,
  //           owner.collegeAlphaSortDescription,
  //         ]);
  //       }
  //     });
  //   });
  //   setColleges(currentColleges);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [programList]);

  useEffect(() => {
    const currentDepartments = [];
    filteredChecksheetList.forEach((checksheet) => {
      const department = checksheet.checksheetObj.department;
      if (
        !currentDepartments.some(([key, value]) => key === department) &&
        (!collegeCode || collegeCode === checksheet.checksheetObj.college) &&
        !!departmentList?.[department]
      ) {
        currentDepartments.push([
          department,
          departmentList[department].description,
        ]);
      }
    });

    setDepartments(currentDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programList, collegeCode]);

  useEffect(() => {
    setTotalProgramsShown(filteredChecksheetList.length);
  }, [filteredChecksheetList]);

  useEffect(() => {
    if (
      selectedChecksheets.length === filteredChecksheetList.length &&
      filteredChecksheetList.length !== 0
    )
      setAllChecksheetsSelected(true);
    else setAllChecksheetsSelected(false);
  }, [filteredChecksheetList.length, selectedChecksheets.length]);

  if (fetchSubplanActivationListIsError)
    return (
      <div>
        <div>
          An error has occurred: {fetchSubplanActivationListError.message}
        </div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div className="pt-1 mt-2 position-relative">
        <div
          className={classNames(
            showDiv ? 'visible opacity-100' : 'invisible opacity-0',
            'container-xl position-fixed bg-white pt-4 program-list-header ps-0 pe-3'
          )}
          style={{ top: '89px', zIndex: 1 }}
        >
          <div className="mb-3">
            <Link to={'/'} className="text-maroon">
              Home
            </Link>
            <span className="text-gray-5 mx-1">/</span>
            {collegeCode ? collegeName : 'All colleges/schools'}
          </div>
          <div className="d-flex justify-content-between mb-2">
            {!!selectedChecksheets.length ? (
              <div className="d-flex gap-3 align-items-center">
                <div className="fw-bold">
                  {selectedChecksheets.length} selected
                </div>
                <div
                  className="button-link"
                  onClick={handleDeselectAllChecksheets}
                  role="button"
                >
                  Deselect all
                </div>
                {userIsProvost && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={DropdownCustomToggle}
                      id="dropdown-custom-components"
                    >
                      <Button
                        label="Set status"
                        icon={['fa', 'chevron-down']}
                        color="maroon"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="py-0" style={{ width: '248px' }}>
                      {StaticData.statusList
                        .filter(
                          (status) =>
                            status.value !== 'DARS' &&
                            status.value !== 'COMPLETE'
                        )
                        .map((status) => (
                          <Dropdown.Item
                            key={status.value}
                            className="d-flex gap-1 px-3 py-2 text-wrap"
                            role="button"
                            onClick={() => handleSetProgress(status.value)}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              className={statusCircle[status.value].color}
                              width={'12px'}
                              style={{
                                marginTop: '4px',
                              }}
                            />
                            <div>{status.label}</div>
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )}
                {/* <Button
                  label="Clear checksheets"
                  color="maroon"
                  onClick={handleClearChecksheets}
                /> */}
              </div>
            ) : (
              <div />
            )}
            {fetchPrograms.isPending ? (
              <LoadingSkeleton width={'140px'} height={'25px'} />
            ) : (
              <span>
                {totalProgramsShown} results{' '}
                {!!searchString && (
                  <span>
                    for <span className="fw-bold">{searchString}</span>
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
            <div className="col-auto" style={{ width: '5%' }}>
              {userIsProvost && (
                <input
                  type="checkbox"
                  id="checkboxChecksheet-all"
                  data-ga-input="checkbox"
                  data-ga-input-name="onclick"
                  data-ga-input-event="select"
                  data-ga-input-action="click"
                  data-ga-input-region="main content"
                  data-ga-input-section="Select all checksheets"
                  checked={allChecksheetsSelected}
                  onChange={handleSelectAllChecksheets}
                  // disabled={filteredChecksheetList?.length === 0}
                />
              )}
            </div>
            <div className="col-auto fw-bold ps-0" style={{ width: '23%' }}>
              Name
            </div>
            <div className="col-auto fw-bold" style={{ width: '12%' }}>
              Plan code
            </div>
            <div className="col-auto fw-bold" style={{ width: '32%' }}>
              Department
            </div>
            <div className="col-auto fw-bold" style={{ width: '21%' }}>
              Status
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ms-1"
                onClick={handleOpenDefinitions}
                role="button"
              />
            </div>
            <div
              className="col-auto d-flex justify-content-end gap-1 align-items-center"
              style={{ width: '6%' }}
            />
          </div>
        </div>
        <div className="d-flex gap-6 justify-content-between mb-6">
          <div className="d-flex gap-3">
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faSearch} />
                <div className="fw-bold">Search</div>
              </div>
              <input
                type="text"
                placeholder="Search proposed checksheets"
                className="border-0 border-bottom border-gray-6 bg-transparent px-3 py-1"
                style={{ width: '275px' }}
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faFilter} />
                <div className="fw-bold">Filters</div>
              </div>
              <div className="filters d-flex flex-wrap gap-1 align-items-center">
                {/* {userIsProvost && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as={DropdownCustomToggle}
                      id="dropdown-custom-components"
                    >
                      <Button
                        label={collegeLabel}
                        icon={['fa', 'chevron-down']}
                        classes={[
                          'btn',
                          collegeFilters.length > 0 ? 'btn-maroon' : 'btn-white',
                          'btn-md',
                        ]}
                      />
                    </Dropdown.Toggle>
  
                    <Dropdown.Menu className="py-0" style={{ width: '540px' }}>
                      <Dropdown.Item
                        className="button-link px-3 mt-2"
                        role="button"
                        onClick={handleUncheckAllColleges}
                      >
                        Uncheck all
                      </Dropdown.Item>
                            {colleges
                              .toSorted(sortByCollege)
                              .map(([key, value]) => (
                                <Dropdown.ItemText key={key}
                                  className="d-flex gap-2 ps-3"
                                  role="button">
                                  <input
                                    type="checkbox"
                                    id={'checkbox-' + key}
                                    data-ga-input="checkbox"
                                    data-ga-input-name="onclick"
                                    data-ga-input-event="select"
                                    data-ga-input-action="click"
                                    data-ga-input-region="main content"
                                    data-ga-input-section={key}
                                    value={key}
                                    checked={collegeFilters.includes(key)}
                                    onChange={(e) => handleFilterCollege(e)}
                                  />
                                  <span
                                  >
                                    {value}
                                  </span>
                                </Dropdown.ItemText>
                              ))}
                    </Dropdown.Menu>
                  </Dropdown>
                )} */}
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={departmentLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        departmentFilters.length > 0
                          ? 'btn-maroon'
                          : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '540px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllDepartments}
                    >
                      Uncheck all
                    </Dropdown.Item>
                    {departments
                      .toSorted(sortByDepartment)
                      .map(([key, value]) => (
                        <Dropdown.ItemText
                          key={key}
                          className="d-flex gap-2 ps-3"
                          role="button"
                        >
                          <input
                            type="checkbox"
                            id={'checkbox-' + key}
                            data-ga-input="checkbox"
                            data-ga-input-name="onclick"
                            data-ga-input-event="select"
                            data-ga-input-action="click"
                            data-ga-input-region="main content"
                            data-ga-input-section={key}
                            value={key}
                            checked={departmentFilters.includes(key)}
                            onChange={(e) => handleFilterDepartment(e)}
                          />
                          <span>{value}</span>
                        </Dropdown.ItemText>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={statusLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        statusFilters.length > 0 ? 'btn-maroon' : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '340px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllStatuses}
                    >
                      Uncheck all
                    </Dropdown.Item>
                    {StaticData.statusList
                      .filter(
                        (status) =>
                          status.value !== 'DARS' && status.value !== 'COMPLETE'
                      )
                      .map((status) => (
                        <Dropdown.ItemText
                          key={status.value}
                          className="d-flex gap-2 ps-3"
                          role="button"
                        >
                          <input
                            type="checkbox"
                            id={'checkbox-' + status.value}
                            data-ga-input="checkbox"
                            data-ga-input-name="onclick"
                            data-ga-input-event="select"
                            data-ga-input-action="click"
                            data-ga-input-region="main content"
                            data-ga-input-section={status.value}
                            value={status.value}
                            checked={statusFilters.includes(status.value)}
                            onChange={(e) => handleFilterStatus(e)}
                          />
                          <span>{status.label}</span>
                        </Dropdown.ItemText>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                {
                  // collegeFilters.length > 0 ||
                  (departmentFilters.length > 0 ||
                    statusFilters.length > 0) && (
                    <div
                      className="button-link"
                      onClick={handleClearAllFilters}
                      role="button"
                    >
                      Clear all filters
                    </div>
                  )
                }
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faCalendar} />
                <div className="fw-bold text-nowrap">Catalog year</div>
              </div>
              <select value={year} onChange={(e) => handleSetYear(e)}>
                {StaticData.yearList.map((sYear) => (
                  <option key={sYear.value} value={sYear.value}>
                    {sYear.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {/* Temporary, remove className when sortBy is fully implemented */}
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faSort} />
                <div className="fw-bold">Sort by</div>
              </div>
              <select onChange={(e) => handleSortBy(e)}>
                <option value="nameA-Z">Name (A-Z)</option>
                <option value="nameZ-A">Name (Z-A)</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mb-2">
          {!!selectedChecksheets.length ? (
            <div className="d-flex gap-3 align-items-center">
              <div className="fw-bold">
                {selectedChecksheets.length} selected
              </div>
              <div
                className="button-link"
                onClick={handleDeselectAllChecksheets}
                role="button"
              >
                Deselect all
              </div>
              {userIsProvost && (
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label="Set status"
                      icon={['fa', 'chevron-down']}
                      color="maroon"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-0" style={{ width: '248px' }}>
                    {StaticData.statusList
                      .filter(
                        (status) =>
                          status.value !== 'DARS' && status.value !== 'COMPLETE'
                      )
                      .map((status) => (
                        <Dropdown.Item
                          key={status.value}
                          className="d-flex gap-1 px-3 py-2 text-wrap"
                          role="button"
                          onClick={() => handleSetProgress(status.value)}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={statusCircle[status.value].color}
                            width={'12px'}
                            style={{
                              marginTop: '4px',
                            }}
                          />
                          <div>{status.label}</div>
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* <Button
                label="Clear checksheets"
                color="maroon"
                onClick={handleClearChecksheets}
              /> */}
            </div>
          ) : (
            <div />
          )}
          {fetchPrograms.isPending ? (
            <LoadingSkeleton width={'140px'} height={'25px'} />
          ) : (
            <span>
              {totalProgramsShown} results{' '}
              {!!searchString && (
                <span>
                  for <span className="fw-bold">{searchString}</span>
                </span>
              )}
            </span>
          )}
        </div>
        <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
          <div className="col-auto" style={{ width: '5%' }}>
            {userIsProvost && (
              <input
                type="checkbox"
                id="checkboxChecksheet-all"
                data-ga-input="checkbox"
                data-ga-input-name="onclick"
                data-ga-input-event="select"
                data-ga-input-action="click"
                data-ga-input-region="main content"
                data-ga-input-section="Select all checksheets"
                checked={allChecksheetsSelected}
                onChange={handleSelectAllChecksheets}
                // disabled={filteredChecksheetList?.length === 0}
              />
            )}
          </div>
          <div className="col-auto fw-bold ps-0" style={{ width: '23%' }}>
            Name
          </div>
          <div className="col-auto fw-bold" style={{ width: '12%' }}>
            Plan code
          </div>
          <div className="col-auto fw-bold" style={{ width: '32%' }}>
            Department
          </div>
          <div className="col-auto fw-bold" style={{ width: '21%' }}>
            Status
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-1"
              onClick={handleOpenDefinitions}
              role="button"
            />
          </div>
          <div
            className="col-auto d-flex justify-content-end gap-1 align-items-center"
            style={{ width: '6%' }}
          />
        </div>
        {fetchPrograms.isPending && getChecksheetIsLoading ? (
          <div className="mt-1">
            {[...Array(8)].map((item, index) => (
              <LoadingSkeleton key={index} width={'100%'} height={'65px'} />
            ))}
          </div>
        ) : totalProgramsShown > 0 ? (
          filteredChecksheetList
            .toSorted(sortBy)
            .map((checksheet, index) => (
              <ProposedRow
                key={checksheet.id}
                checksheet={checksheet}
                checksheetList={checksheetList}
                programList={programList}
                filters={statusFilters}
                index={index}
                refetch={refetchChecksheets}
                isLoading={getChecksheetIsLoading}
                handleOpenDefinitions={handleOpenDefinitions}
                selectedChecksheets={selectedChecksheets}
                handleSelectChecksheet={handleSelectChecksheet}
                user={user}
                collegeList={collegeList}
                departmentList={departmentList}
                refetchChecksheets={refetchChecksheets}
              />
            ))
        ) : (
          <div className="p-2 text-gray-6 fst-italic">
            No proposed checksheets found
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={handleCloseModal} className="pt-8">
        {/* {deleteProgramsModal ? (
          <div className="d-flex gap-2 align-items-center p-3">
            <FontAwesomeIcon icon={faSpinner} spin />
            <div className="text-nowrap">Deleting checksheets...</div>
          </div>
        ) : ( */}
        <NewProposedProgramForm
          programList={programList}
          proposedList={checksheetList}
          collegeList={collegeList}
          handleClose={handleCloseModal}
          user={user}
          year={year}
          refetchChecksheets={refetchChecksheets}
        />
        {/* )} */}
      </Modal>
    </>
  );
};

export default ProposedRows;
