import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import useServiceAuth from 'auth/useServiceAuth';
import {
  faChevronDown,
  faCircle,
  faEllipsisVertical,
  faLock,
  faUnlock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import { setCurrentChecksheet } from 'state/slices/currentSelectionSlice';
import {
  checksheetDelete,
  checksheetLock,
  checksheetUpdateProgress,
} from 'apis/checksheetAPIs';
import StaticData from './StaticData';
// import { Button } from '@asu/components-core';
import { classNames } from 'utils';
import { Modal } from 'react-bootstrap';
import NewChecksheet from './NewChecksheetForm';
import CopyChecksheet from './CopyChecksheetForm';
import { clearValidation } from 'state/slices/validationSlice';

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ProgramRow = ({
  program,
  activatedSubplans,
  programList,
  checksheetList,
  filters,
  index,
  refetch,
  isLoading,
  handleOpenDefinitions,
  selectedChecksheets,
  handleSelectChecksheet,
  user,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [checksheetFound, setChecksheetFound] = useState(null);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);
  const [checksheetStatus, setChecksheetStatus] = useState(null);
  const [showSubplans, setShowSubplans] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isNewChecksheet, setNewChecksheet] = useState(false);
  const [preventLock, setPreventLock] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  // const [isChecked, setIsChecked] = useState(selectedPrograms.includes());

  // const checksheetList = useSelector((state) => state.dataLists.checksheetList);

  const degree = program.degreeDescriptionShort
    ? program.degreeDescriptionShort === 'CERT'
      ? ', Certificate'
      : `, ${program.degreeDescriptionShort}`
    : program.acadPlanType === 'MIN'
      ? ', Minor'
      : null;

  // if (!!checksheetFound) {
  //   user = checksheetFound.updatedBy;
  //   date = new Date(checksheetFound.updatedOn).toLocaleDateString('en-us', {
  //     month: 'short',
  //     day: 'numeric',
  //     year: 'numeric',
  //   });
  // }

  const campusesOffered = [];
  if (program.campusesOffered && program.campusesOffered.length > 0) {
    program.campusesOffered.forEach((campus) => {
      const obj = { ...campus };
      const campusFound = StaticData.campusUndergradList.find(
        (campusUndergrad) => campusUndergrad.value === campus.campusCode
      );
      if (campusFound?.label) obj.label = campusFound.label;
      campusesOffered.push(obj);
    });
  }

  const owners = [];
  let mainOwner = {};
  if (program.owners.length > 0) {
    let highestPercent = 0;
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
          departmentAcadOrg: programOwner.departmentAcadOrg,
          departmentDescription: programOwner.departmentDescription,
        });
      }

      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const userIsProvost = user?.role?.includes('PROVOST');
  const userIsDars = user?.role?.includes('DARS');
  const userIsCollege = user?.role?.includes('COLLEGE');
  const isSameCollege = user?.college?.includes(mainOwner.collegeAcadOrg);
  const userIsDepartment = user?.role?.includes('DEPARTMENT');
  const isSameDepartment = user?.department?.includes(
    mainOwner.departmentAcadOrg
  );

  const { mutate: deleteChecksheet } = useMutation({
    mutationFn: checksheetDelete,
    onSuccess: async () => {
      refetch();
    },
  });

  const {
    mutate: lockChecksheet,
    // error: lockChecksheetError,
    // isError: lockChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetLock,
    onSuccess: () => {
      console.log('Checksheet locked successfully');
      refetch();
      setPreventLock(false);
    },
    onError: () => {
      setPreventLock(false);
    },
  });

  const {
    mutate: updateChecksheetProgress,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetUpdateProgress,
    onSuccess: async (data, { token }) => {
      refetch();
      console.log('updateChecksheetProgress success');
    },
  });

  const handleCloseModal = () => setShowModal(false);

  const handleSetChecked = (e) => {
    e.stopPropagation();
    handleSelectChecksheet(checksheetFound.id);
  };

  const handleDeleteChecksheet = async (e, id) => {
    e.stopPropagation();

    const confirmDelete = window.confirm(
      `Are you sure you want to delete the checksheet for ${
        program.subplan
          ? program.subplan.description
          : program.acadPlanDescription
      }${degree}?`
    );

    if (confirmDelete) {
      const token = await getAccessToken();
      deleteChecksheet({ id, token });

      setMenuOpen(false);
    }
  };

  const handleSetProgress = async (e, progress) => {
    e.stopPropagation();
    const jsonData = {
      ids: [checksheetFound.id],
      progress,
      action: 'changed',
    };
    const token = await getAccessToken();

    await updateChecksheetProgress({
      jsonData: jsonData,
      token: token,
    });

    setMenuOpen(false);
    setShowStatusOptions(false);
  };

  const handleOpenCopyChecksheet = (e, isNewChecksheet) => {
    e.stopPropagation();

    dispatch(
      setCurrentChecksheet({
        program,
        owners,
        mainOwner,
        campusesOffered,
        checksheet: { ...checksheetFound },
        isNewChecksheet,
      })
    );

    setMenuOpen(false);
    setNewChecksheet(isNewChecksheet);
    setShowModal(true);
  };

  const handleShowMenu = (e) => {
    e.stopPropagation();
    setMenuOpen(!isMenuOpen);
  };

  // const { mutate: verifyChecksheet } = useMutation({
  //   mutationFn: checksheetGet,
  // });

  const handleLockUnlock = async (e, action) => {
    e.stopPropagation();
    let lockCheck = true;

    setPreventLock(true);

    const jsonData = {
      action,
    };

    const token = await getAccessToken();
    // const verifyCheck = await verifyChecksheet({ id: checkId, token });

    // if (
    //   !!verifyCheck?.lockedBy &&
    //   JSON.parse(verifyCheck.lockedBy).asurite !== asurite
    // ) {
    //   lockCheck = false;
    // }

    if (lockCheck) {
      await lockChecksheet({
        id: checksheetFound.id,
        jsonData,
        token,
      });
    } else {
      setPreventLock(() => false);
      alert(
        'Another user has locked this checksheet. Refresh the page to see new updates.'
      );
    }

    // if (lockChecksheetIsError) setError(lockChecksheetError.message);
  };

  useEffect(() => {
    const found = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    setChecksheetFound(found);

    if (found?.status) {
      const statusFound = StaticData.statusList.find(
        (status) => status.value === found.status
      );

      if (!!statusFound) setChecksheetStatus(statusFound.label);
    }

    const isDisabled =
      !!filters.length &&
      ((!!found?.status && !filters.includes(found.status)) ||
        (!found && !filters.includes('NONE')));

    setIsFilterDisabled(isDisabled);
  }, [checksheetList, filters, program.acadPlanCode, program.subplan]);

  useEffect(() => {
    let hasActiveSubplans = false;

    if (!!activatedSubplans && programList) {
      const programsWithSubplans = programList.filter((prog) => {
        if (prog.acadPlanCode === program.acadPlanCode) return true;

        return false;
      });

      programsWithSubplans.forEach((prog) => {
        if (
          prog.subplan?.acadSubPlanCode &&
          activatedSubplans[prog.subplan.acadSubPlanCode]
        )
          hasActiveSubplans = true;
      });

      if (!!filters.length) {
        hasActiveSubplans = false;

        Object.keys(activatedSubplans).forEach((key) => {
          const found = checksheetList.find(
            (checksheet) =>
              checksheet.id.split('*')[2] === program.acadPlanCode &&
              checksheet.id.split('*')[3] === key
          );

          if (
            (found && filters.includes(found.status)) ||
            (!found && filters.includes('NONE'))
          )
            hasActiveSubplans = true;
        });
      }
    }

    setShowSubplans(!program.subplan && hasActiveSubplans);
  }, [
    activatedSubplans,
    checksheetList,
    filters,
    program.acadPlanCode,
    program.subplan,
    programList,
  ]);

  if (!program) return <div>Loading...</div>;

  return (
    <>
      <div>
        <div
          key={program.acadPlanCode}
          className={classNames(
            isFilterDisabled && 'bg-gray-1 text-gray-6 pe-none',
            !!checksheetFound && userIsProvost && 'program-row',
            'row mx-0'
          )}
          onClick={
            !!checksheetFound?.id && userIsProvost
              ? (e) => handleSetChecked(e)
              : undefined
          }
          role={!!checksheetFound?.id && userIsProvost ? 'button' : undefined}
        >
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              !!program.subplan && 'ms-7',
              'col-auto pt-2'
            )}
            style={{ width: '5%' }}
          >
            {!!checksheetFound?.id && userIsProvost && (
              <input
                type="checkbox"
                className="program-row-checkbox"
                id={`checkboxChecksheet-${
                  program.subplan
                    ? program.subplan.acadSubPlanCode
                    : program.acadPlanCode
                }`}
                data-ga-input="checkbox"
                data-ga-input-name="onclick"
                data-ga-input-event="select"
                data-ga-input-action="click"
                data-ga-input-region="main content"
                data-ga-input-section={`Select checksheet for ${
                  program.subplan
                    ? program.subplan.acadSubPlanCode
                    : program.acadPlanCode
                }`}
                checked={selectedChecksheets.includes(checksheetFound.id)}
                onChange={(e) => handleSetChecked(e)}
                onClick={(e) => e.stopPropagation()}
              />
            )}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto ps-0 fw-bold py-2'
            )}
            style={{ width: !!program.subplan ? 'calc(23% - 3.5rem)' : '23%' }}
          >
            {!!checksheetFound ? (
              <Link
                className="underline-hover text-gray-7"
                to={`/checksheet?id=${checksheetFound.id}`}
                onClick={() => dispatch(clearValidation())}
              >
                {program.subplan
                  ? program.subplan.description
                  : program.acadPlanDescription}
                {degree}
              </Link>
            ) : (
              <div
                className={classNames(
                  !userIsProvost && 'pe-none',
                  'underline-hover text-gray-7'
                )}
                onClick={
                  userIsProvost
                    ? (e) => handleOpenCopyChecksheet(e, true)
                    : undefined
                }
                role="button"
              >
                {program.subplan
                  ? program.subplan.description
                  : program.acadPlanDescription}
                {degree}
              </div>
            )}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '12%' }}
          >
            {program.subplan
              ? program.subplan.acadSubPlanCode
              : program.acadPlanCode}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '15%' }}
          >
            {
              !isFilterDisabled && mainOwner.departmentDescription
              // owners.map((owner, index) => (
              //   <div
              //     key={owner.departmentAcadOrg}
              //     className={classNames(index !== owners.length - 1 && 'mb-1')}
              //   >
              //     {owner.departmentDescription}
              //   </div>
              // ))
            }
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '17%' }}
          >
            {!isFilterDisabled &&
              campusesOffered.map((campus, index) => (
                <span key={campus.campusCode}>
                  {campus.label}
                  {index !== campusesOffered.length - 1 && ', '}
                </span>
              ))}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto py-2'
            )}
            style={{ width: '21%' }}
          >
            {!isFilterDisabled &&
              (isLoading ? (
                <div>Loading...</div>
              ) : (
                <div>
                  <div>
                    {!!checksheetFound?.status && !!checksheetStatus ? (
                      <div className="d-flex gap-1">
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={statusCircle[checksheetFound.status].color}
                          width={'12px'}
                          style={{
                            marginTop: '4px',
                          }}
                        />
                        {checksheetStatus}
                      </div>
                    ) : (
                      <span style={{ marginLeft: '20px' }}>
                        No checksheet exists
                      </span>
                    )}
                  </div>
                  {!!checksheetFound?.lockedBy &&
                    !!Object.keys(checksheetFound.lockedBy).length && (
                      <div
                        className="d-flex gap-1 align-items-center px-1 mt-1 bg-gray-2 rounded-1"
                        style={{ width: 'fit-content' }}
                      >
                        <FontAwesomeIcon
                          icon={faLock}
                          className="text-gray-6"
                        />
                        <div>Locked by {checksheetFound.lockedBy.asurite}</div>
                      </div>
                    )}
                </div>
              ))}
          </div>
          <div
            className={classNames(
              !!index && 'border-top border-gray-3',
              'col-auto d-flex gap-3 justify-content-end align-items-center pe-1'
            )}
            style={{
              height: 'fit-content',
              width: '7%',
              padding: '12px 0 8px',
            }}
          >
            {/* {isLoading ? (
              <div>Loading...</div>
            ) : (
              <div className="flex-grow-1 d-flex justify-content-center">
                {checksheetFound ? (
                  <Button
                    label="View program"
                    color="maroon"
                    size="small"
                    onClick={() =>
                      navigate(`/checksheet?id=${checksheetFound.id}`)
                    }
                  />
                ) : (
                  <Button
                    label="Create checksheet"
                    color="gold"
                    size="small"
                    onClick={() => handleOpenCopyChecksheet(true)}
                  />
                )}
              </div>
            )} */}

            <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
              <div className="position-relative">
                {userIsProvost && !isFilterDisabled && (
                  <div
                    className={classNames(
                      isMenuOpen
                        ? 'visible opacity-100'
                        : 'invisible opacity-0',
                      'mini-menu-container bg-white px-2 py-2 border border-gray-3'
                    )}
                  >
                    <div className="mini-menu-option text-nowrap px-1 mb-1 rounded-1">
                      <div
                        className="d-flex justify-content-between align-items-center"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowStatusOptions(!showStatusOptions);
                        }}
                      >
                        <div>Set status</div>
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className={classNames(
                            showStatusOptions && 'rotate-180'
                          )}
                          style={{ height: '14px' }}
                        />
                      </div>
                    </div>
                    <div
                      className={classNames(
                        showStatusOptions && 'show pb-2',
                        'status-options'
                      )}
                    >
                      {StaticData.statusList.map((status) => (
                        <div
                          key={status.value}
                          className="mini-menu-option rounded-1 d-flex gap-1 px-1 py-1 text-wrap"
                          onClick={(e) => handleSetProgress(e, status.value)}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={statusCircle[status.value].color}
                            width={'12px'}
                            style={{
                              marginTop: '4px',
                            }}
                          />
                          <div>{status.label}</div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={classNames(
                        !checksheetFound && 'pe-none opacity-50',
                        'mini-menu-option text-nowrap px-1 mb-1 rounded-1'
                      )}
                      onClick={(e) => handleOpenCopyChecksheet(e, false)}
                      role="button"
                    >
                      Copy checksheet
                    </div>
                    <div
                      className={classNames(
                        !checksheetFound && 'pe-none opacity-50',
                        'mini-menu-option text-nowrap px-1 rounded-1'
                      )}
                      onClick={(e) =>
                        handleDeleteChecksheet(e, checksheetFound.id)
                      }
                      role="button"
                    >
                      Delete
                    </div>
                  </div>
                )}
                <div className="d-flex gap-1 align-items-center">
                  {!!checksheetFound?.lockedBy &&
                    !!Object.keys(checksheetFound.lockedBy).length &&
                    user?.role &&
                    user.asurite &&
                    (checksheetFound.lockedBy.asurite === user.asurite ||
                      userIsProvost) && (
                      <div
                        className={classNames(
                          preventLock && 'pe-none opacity-50',
                          'd-flex gap-1 align-items-center border border-maroon rounded-circle'
                        )}
                        style={{
                          height: '28px',
                          width: '28px',
                          padding: '6px',
                        }}
                        onClick={(e) => handleLockUnlock(e, 'unlock')}
                        role="button"
                        title="Unlock"
                      >
                        <FontAwesomeIcon
                          icon={faUnlock}
                          className="text-maroon"
                          style={{ height: '14px', width: '14px' }}
                        />
                      </div>
                    )}
                  {(!checksheetFound?.lockedBy ||
                    !Object.keys(checksheetFound.lockedBy).length) &&
                    !!checksheetFound?.status &&
                    user?.role.includes(checksheetFound.status) &&
                    (userIsProvost ||
                      ((userIsCollege || userIsDars) && isSameCollege) ||
                      (userIsDepartment && isSameDepartment)) && (
                      <div
                        className={classNames(
                          preventLock && 'pe-none opacity-50',
                          'd-flex gap-1 align-items-center bg-maroon rounded-circle'
                        )}
                        style={{
                          height: '28px',
                          width: '28px',
                          padding: '7px',
                        }}
                        onClick={(e) => handleLockUnlock(e, 'lock')}
                        role="button"
                        title="Lock"
                      >
                        <FontAwesomeIcon
                          icon={faLock}
                          className="text-white"
                          style={{ height: '14px', width: '14px' }}
                        />
                      </div>
                    )}
                  {userIsProvost && !isFilterDisabled && (
                    <div
                      className={classNames(
                        isMenuOpen && 'bg-gray-3',
                        'vertical-ellipsis p-1 lh-1 rounded-1'
                      )}
                      onClick={(e) => handleShowMenu(e)}
                      role="button"
                    >
                      <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        style={{ height: '20px' }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        </div>
        {showSubplans && (
          <div className="d-flex mt-1 ms-7">
            <div
              className="px-1 text-success-custom bg-success-custom"
              style={{
                fontSize: '14px',
              }}
            >
              Active subplans
            </div>
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        {isNewChecksheet ? (
          <NewChecksheet
            programList={programList}
            checksheetList={checksheetList}
            handleClose={handleCloseModal}
          />
        ) : (
          <CopyChecksheet
            programList={programList}
            checksheetList={checksheetList}
            handleClose={handleCloseModal}
          />
        )}
      </Modal>
    </>
  );
};

export default ProgramRow;
